import React, { Component } from 'react';
//import siteLogo from './logo.svg';
import './App.css';
import {ChocolateNav} from './components/ChocolateNav';
import {ChocolateBars} from './components/ChocolateBars';

class App extends Component {
    constructor(props){
        super(props);
        this.onBarSecChange = this.onBarSecChange.bind(this);
        this.onBarKeyChange = this.onBarKeyChange.bind(this);
        this.state = {
            barSec: 'bars1',
            heroKey : 0
        }
    }

    onBarSecChange(sec){
        this.setState({barSec:sec});
    }
    onBarKeyChange(k){
        this.setState({heroKey:k});
        console.log('hello '+k);
    }
    render() {
        return (
            <div className='container'>
                <ChocolateNav barSec={this.state.barSec} onBarSecChange={this.onBarSecChange} onBarKeyChange={this.onBarKeyChange} />
                <ChocolateBars barSec={this.state.barSec} onBarKeyChange={this.onBarKeyChange} heroKey={this.state.heroKey} />
            </div>
        );
    }
}

export default App;
