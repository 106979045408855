import React from 'react';
import myBars1 from '../data/bars1.json';
import myBars2 from '../data/bars2.json';
import myBars3 from '../data/bars3.json';
import myBars4 from '../data/bars4.json';

export class ChocolateBars extends React.Component{
    constructor(props){
      super(props);
      this.state = {
          barSec: props.barSec,
          bars1 : myBars1.bars,
          bars2 : myBars2.bars,
          bars3 : myBars3.bars,
          bars4 : myBars4.bars,
          heroKey : props.heroKey
      };
  }

//chooses which object of data to use baed on selection.
  BarsDisplaySec(sec){
      switch(sec){
          case 'bars1':
          return this.state.bars1;
          case 'bars2' :
          return this.state.bars2;
          case 'bars3' :
          return this.state.bars3;
          case 'bars4' :
          return this.state.bars4;
          default :
          return this.state.bars1;
      }
  }

//Loads the hero bar baed on selected bars selection and hero key
  BarsDisplayHero(sec){
      let optSec = this.props.heroKey;
      return(
          sec[optSec]
      );
  }

//updates the key for the hero bar to display
  BarsUpdateHero(k){
      let keyVal = k;
      this.props.onBarKeyChange(keyVal);
  }

  componentDidMount(){}
  componentWillUnmount(){}

    render(){
        const barList = this.props.barSec === "" ? this.state.bars1 : this.BarsDisplaySec(this.props.barSec);
        const hero = this.BarsDisplayHero(barList);
        //Bar List items
        const barListItems = Object.keys(barList).map(key =>
            <div className="barList-row" key={key} onClick={()=>this.BarsUpdateHero(key)} ><img className="barList-row-img" alt='' src={barList[key].img} />{barList[key].name}</div>
        );

        return(
            <div className="heroBar row">
                <div className="col-sm-7">
                    <h1>{hero.brand} - {hero.name}</h1>
                    <img className='heroBar-img' src={hero.img} alt={hero.name} />
                </div>
              <div className="col-sm-5">
                  <div className='heroBar-info'>{hero.info}</div>
                  <a className="btn btn-lg ybShopBtn heroBar-link" href={hero.link} role="button" target="_blank">Shop Now</a>

                  <div className="pre-scrollable barList scroll">
                      {barListItems}
                  </div>
              </div>
            </div>
        );
    }
}
