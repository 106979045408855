import React from 'react';

export class ChocolateNav extends React.Component{
     constructor(props){
          super(props);
          //Nav controle
          this.state = {
              barSec: props.barSec
        };
          this.navClick = this.navClick.bind(this);
     }

     componentDidMount(){}
     componentWillUnmount(){

     }
     //updates the nave item to highlight and updates the selection prop from app.
     navClick(e){
          this.props.onBarSecChange(e.target.id);
          this.props.onBarKeyChange(0);
          var navRow = document.getElementById('chocolateNavRow');
          var navs = navRow.getElementsByClassName('navOn');
          navs[0].classList.remove("navOn");
          e.target.classList.add('navOn');
     }

     render(){
          return(
            <div id='chocolateNavRow'>
                <div className="chocolateNavCont">
                    <button className="chocolateAppBtn btn1 navOn" id="bars1" onClick={this.navClick}>
                        Fruity, Single Origin.
                    </button>
                </div>
                <div className="chocolateNavCont">
                    <button className="chocolateAppBtn btn2" id="bars2" onClick={this.navClick}>
                        Bold, Single Origin.
                    </button>
                </div>
                <div className="chocolateNavCont">
                    <button className="chocolateAppBtn btn3" id="bars3" onClick={this.navClick}>
                        Inclusion Creations.
                    </button>
                </div>
                <div className="chocolateNavCont">
                    <button className="chocolateAppBtn btn4" id="bars4" onClick={this.navClick}>
                        Creamy Sweetness.
                    </button>
                </div><div className="clearfix visible-lg-block "></div>
            </div>
          );
     }
}
